import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { lottoListType } from "../../types";

import { Box, Button, Grid, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa6";
import { GoClock } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import LivePicture from './../../assets/images/ajax-ic-live-animate.webp';
import DiceSpin from "../Loading/DiceSpin";
import moment from "moment";

const LottoTab = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [lottoList, setLottoList] = useState<lottoListType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadLottoData();
    }, []);

    const calculateRemainingTime =(endTime: Date, elementClockId: string) => {
        const splitInput = elementClockId.split("-");
        const elementInput = document.getElementById(elementClockId) as HTMLElement;
        const elementClockInput = document.getElementById(`icon-clock-${splitInput[2]}-${splitInput[3]}`) as HTMLElement;
        const elementOutInput = document.getElementById(`icon-out-${splitInput[2]}-${splitInput[3]}`) as HTMLElement;
        const elementImg = document.getElementById(`img-lotto-${splitInput[2]}-${splitInput[3]}`) as HTMLElement;
        if(elementInput)
        {
            const now = moment();
            const duration = moment.duration(moment(endTime).diff(now));

            if(Math.floor(duration.asHours()) < 0 && duration.minutes() < 0)
            {
                elementInput.innerHTML = `ปิดรับแทง`;
                elementInput.style.color = '#cf1f2d';

                if(elementClockInput)
                {
                    elementClockInput.style.display = 'none';
                }

                if(elementOutInput)
                {
                    elementOutInput.style.display = 'block';
                }

                if(elementImg)
                {
                    elementImg.style.filter = 'grayscale(100%)';
                }
            }
            else if (Math.floor(duration.asHours()) > 24) {
                const days = Math.floor(Math.floor(duration.asHours()) / 24);
                elementInput.innerHTML = `${days} วัน`;
                elementInput.style.color = 'black';

                if(elementClockInput)
                {
                    elementClockInput.style.display = 'block';
                }

                if(elementOutInput)
                {
                    elementOutInput.style.display = 'none';
                }

                if(elementImg)
                {
                    elementImg.style.filter = 'unset';
                }
            } else {
                elementInput.innerHTML = `${Math.floor(duration.asHours()).toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
                
                if(elementClockInput)
                {
                    elementClockInput.style.display = 'block';
                }

                if(elementOutInput)
                {
                    elementOutInput.style.display = 'none';
                }

                if(elementImg)
                {
                    elementImg.style.filter = 'unset';
                }
            }
        }
        
        setTimeout(() => {
            calculateRemainingTime(endTime, elementClockId)
        }, 1000);
    }

    const loadLottoData = async () => {
        setLoading(true);
        try {
            const service = new ApiService();
            const lottoData = await service.getLottoList();

            if(lottoData.data.status_code === 200)
            {
                const tmp_lottoData: lottoListType[] = [];
                lottoData.data.data.forEach((element: lottoListType) => {
                    tmp_lottoData.push({
                        id: element.id,
                        name: element.name,
                        img_path: element.img_path,
                        start_dt: element.start_dt,
                        end_dt: element.end_dt,
                        period: element.period,
                        remainingTime: null,
                        sort: element.sort
                    });

                    if(element.end_dt)
                    {
                        calculateRemainingTime(element.end_dt, `lotto-clock-${element.name}-${element.id}`)
                    }
                }, []);
                setLottoList(tmp_lottoData);
                setLoading(false);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ไม่สามารถโหลดข้อมูลเกมได้ในขณะนี้ กรุณาลองรีเฟรชหน้าเว็บใหม่อีกครั้ง'
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const startPlayLotto = async (lotto_id: number) => {
        if(!cookies.get('access_token'))
        {
            return navigate('/login');

            // return Swal.fire({
            //     icon: 'warning',
            //     title: 'แจ้งเตือน',
            //     text: 'กรุณาเข้าสู่ระบบก่อนเข้าเล่นค่ะ'
            // });
        }

        navigate(`/play/${lotto_id}`);
    }

    return (
        !loading ? <>
            {
                lottoList.sort((a: lottoListType, b: lottoListType) => {
                    const now = new Date(); // เวลาปัจจุบัน
  
                    // แปลง end_dt เป็น Date object เพื่อให้เปรียบเทียบได้
                    const endDtA = a.end_dt ? new Date(a.end_dt) : null;
                    const endDtB = b.end_dt ? new Date(b.end_dt) : null;
                    
                    // ถ้า end_dt ของทั้งคู่เป็น null หรือหมดเวลา
                    if ((endDtA === null || endDtA < now) && (endDtB === null || endDtB < now)) {
                        return 0; // ไม่ต้องสลับถ้าทั้งคู่หมดเวลา
                    } else if (endDtA === null || endDtA < now) {
                        return 1; // ถ้า a.end_dt หมดเวลา ให้ a อยู่หลัง
                    } else if (endDtB === null || endDtB < now) {
                        return -1; // ถ้า b.end_dt หมดเวลา ให้ b อยู่หลัง
                    } else {
                        return a.sort - b.sort; // ถ้าไม่มีรายการหมดเวลา ให้เรียงตาม sort
                    }
                }).map((element: lottoListType, index: number) => {
                    return (
                        <Grid item xs={4} md={2} key={`game-${index}`}>
                            <Box sx={{
                                position: 'relative'
                            }}>
                            </Box>
                            <Box sx={{ position: 'relative', height: 'auto', width: '100%', color: 'black' }}>
                                <Box sx={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(0, 0, 0, .5)',
                                    height: '100%',
                                    width: '100%',
                                    color: 'black',
                                    opacity: '0%',
                                    borderRadius: 2,
                                    zIndex: 10,
                                    transition: 'all .2s',
                                    '&:hover': {
                                        opacity: '100%'
                                    },
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Box width={`100%`} display={`flex`} justifyContent={`center`}>
                                        <Button sx={{
                                            backgroundColor: '#000000',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#242323'
                                            }
                                        }} onClick={() => {
                                            startPlayLotto(element.id)
                                        }} disabled={element.end_dt === null ? true : moment(element.end_dt).isSameOrBefore(moment())}>
                                            <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                <FaPlay />&nbsp;เข้าเล่น
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                {/* <Box sx={{ position: 'absolute', zIndex: 2, display: 'flex', justifyContent: 'flex-end', width: '95%', mt: 1 }}>
                                    <img src={LivePicture} alt={`game-live-${index}`} style={{ width: 60 }} />
                                </Box> */}
                                <Box sx={{ position: 'absolute', zIndex: 2, display: 'flex', flexDirection: `column`, justifyContent: 'flex-end', width: '100%', my: 1, height: '100%' }}>
                                    <Typography color={'black'} align="center" mb={-1} sx={{
                                        fontSize: {
                                            xs: 10,
                                            sm: 10,
                                            md: 18,
                                            lg: 18
                                        }
                                    }}>
                                        {element.name}
                                    </Typography>
                                </Box>
                                <Box sx={{ position: 'absolute', zIndex: 2, display: 'flex', flexDirection: `column`, justifyContent: 'flex-end', width: '100%', mt: 1, height: '100%' }}>
                                    <Box display={`flex`} justifyContent={`flex-end`} width={`100%`}>
                                        <Box sx={{ backgroundColor: 'white', borderRadius: 2, mr: .5, mb: 2.4 }} width={`60%`}>
                                            <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} fontSize={14} fontWeight={`bold`}>
                                                {
                                                    element.end_dt === null ? <MdOutlineCancel fontWeight={`bold`} color="#cf1f2d" /> : <>
                                                        <GoClock fontWeight={`bold`} id={`icon-clock-${element.name}-${element.id}`} />
                                                        <MdOutlineCancel style={{ display: 'none' }} fontWeight={`bold`} color="#cf1f2d" id={`icon-out-${element.name}-${element.id}`} />
                                                    </>
                                                }
                                                &nbsp;
                                                <Typography id={`lotto-clock-${element.name}-${element.id}`} fontWeight={`bold`} sx={{
                                                    fontSize: {
                                                        xs: 9,
                                                        sm: 9,
                                                        md: 14,
                                                        lg: 14
                                                    },
                                                    color: element.end_dt === null ? "#cf1f2d" : "black"
                                                }}>
                                                    {
                                                        element.end_dt === null ? "ปิดรับแทง" : '00:00'
                                                    }
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <img id={`img-lotto-${element.name}-${element.id}`} src={`${process.env.REACT_APP_API_URL}/image/${element.img_path}`} alt={`lotto-${element.name}`} style={{ width: '100%', borderRadius: 2, filter: element.end_dt === null ? 'grayscale(100%)' : 'unset' }} />
                            </Box>
                        </Grid>
                    )
                })
            }
        </> : <Box width={`100%`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} mt={3}>
            <DiceSpin />
        </Box>
    )
}

export default LottoTab;