import { Box, Button, CircularProgress, Divider, FormControl, FormLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import { useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
}

const AffiliateMain = ({ isOpenState, setIsOpenState}: PropsType) => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [affiliateUrl, setAffiliateUrl] = useState<string>('');
    const [loseTotal, setLoseTotal] = useState<number>(0);
    const [returnCreditTotal, setReturnCreditTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(isOpenState);
    }, [isOpenState]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('คัดลอกเรียบร้อยแล้ว');
    }

    useEffect(() => {
        if(!isOpenState)
        {
            setLoaded(false);
            setAffiliateUrl('');
            return;
        }

        loadData();
    }, [isOpenState]);

    const loadData = async () => {
        try {
            if(!cookies.get('access_token'))
            {
                setLoaded(true);
                setIsOpenState(false);
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณาเข้าสู่ระบบก่อนค่ะ'
                });
            }
            else
            {
                const service = new ApiService();
                const getAffiliateUrl = await service.getMemberAffiliateUrl(cookies.get('access_token'));
                if(getAffiliateUrl.data.status_code === 200)
                {
                    setAffiliateUrl(getAffiliateUrl.data.url);
                    setLoaded(true);
                }
                else
                {
                    setLoaded(true);
                    setIsOpenState(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ'
                    })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getReturnWinloseCredit = async () => {
        if(loading)
        {
            return;
        }
        setLoading(true);
        try {
            const service = new ApiService();
            const getReturnCredit = await service.getReturnFriendWinloseCredit(cookies.get('access_token'));
            if(getReturnCredit.data.status_code !== 200)
            {
                setLoading(false);
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: getReturnCredit.data.message || "ไม่ทราบสาเหตุข้อผิดพลาด"
                });
            }

            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                text: `รับยอดเสียคืนเรียบร้อยแล้ว จำนวน ${parseFloat(String(getReturnCredit.data.returnCreditTotal)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} เครดิต`
            }).then(() => {
                navigate('/');
            });
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        setLoaded(false);
        getReturnWinloseCreditData();
    }

    const getReturnWinloseCreditData = async () => {
        try {
            const service = new ApiService();
            const getData = await service.getReturnFriendWinloseCreditData(cookies.get('access_token'));

            if(getData.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: getData.data.message || "ไม่ทราบสาเหตุข้อผิดพลาด"
                }).then(() => {
                    navigate('/');
                });
            }

            setLoseTotal(getData.data.loseTotal);
            setReturnCreditTotal(getData.data.returnCreditTotal);
            setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Toaster />
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                zIndex={40}
                gap={2}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(0, 0, 0, .6)',
                        md: 'rgba(0, 0, 0, .5)'
                    },
                    overflow: 'scroll'
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: '15px 15px 15px 15px',
                            minWidth: '350px',
                            width: 'auto',
                            maxWidth: '500px'
                        }}>
                            <Box display={`flex`} justifyContent={`space-around`}>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} p={2} bgcolor={`#000000`} width={`100%`}
                                    sx={{
                                        borderRadius: '15px 15px 0px 0px',
                                    }}
                                >
                                    <Typography color={'white'}>
                                        แนะนำเพื่อน
                                    </Typography>
                                </Box>
                            </Box>
                            <Box bgcolor={'white'} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                                <Box p={2}>
                                    <Typography fontSize={20} align="center">
                                        แนะนำเพื่อนรับ 5% จากยอดเสียของเพื่อน
                                    </Typography>
                                    <Grid container spacing={2} sx={{ mt: .5 }}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <FormLabel htmlFor="user_affiliate">
                                                    <Typography color={`black`} fontSize={13}>
                                                        ลิงก์ของคุณ
                                                    </Typography>
                                                </FormLabel>
                                                <OutlinedInput
                                                    name='user_affiliate'
                                                    type={`tel`}
                                                    size='small'
                                                    fullWidth
                                                    autoComplete="user_affiliate"
                                                    sx={
                                                        {
                                                            backgroundColor: 'white'
                                                        }
                                                    }
                                                    value={affiliateUrl.replace('{appurl}', process.env.REACT_APP_BASE_URL || "")}
                                                    readOnly
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="button" fullWidth sx={
                                                {
                                                    backgroundColor: '#000000',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#000000',
                                                        color: 'white',
                                                    }
                                                }
                                            } onClick={() => {
                                                copyToClipboard(`${affiliateUrl.replace('{appurl}', process.env.REACT_APP_BASE_URL || "")}`)
                                            }}>
                                                <Typography fontSize={16} fontWeight={600}>
                                                    คัดลอกลิงก์
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <FormLabel htmlFor="user_credit_return_total">
                                                    <Typography color={`black`} fontSize={13}>
                                                        ยอดเสียเพื่อนที่จะได้รับคืน (5% ของยอดเสีย)
                                                    </Typography>
                                                </FormLabel>
                                                <OutlinedInput
                                                    name='user_credit_return_total'
                                                    type='text'
                                                    size='small'
                                                    fullWidth
                                                    autoComplete="user_credit_return_total"
                                                    sx={
                                                        {
                                                            backgroundColor: 'white'
                                                        }
                                                    }
                                                    readOnly
                                                    value={returnCreditTotal.toFixed(2)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button onClick={getReturnWinloseCredit} fullWidth sx={
                                                {
                                                    backgroundColor: '#000000',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#000000',
                                                        color: 'white',
                                                    }
                                                }
                                            } disabled={loading}>
                                                <Typography fontSize={16} fontWeight={600}>
                                                    {
                                                        loading ? <CircularProgress size={24} sx={
                                                            {
                                                                color: 'white'
                                                            }
                                                        } /> : "รับยอดเสียคืน"
                                                    }
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} mt={2}>
                                        <Typography fontSize={14}>
                                            หมายเหตุ
                                        </Typography>
                                        <ul>
                                            <li>
                                                <Typography fontSize={14}>
                                                    สามารถรับยอดเสียของเพื่อนคืนของแต่ละสัปดาห์ได้แค่วันจันทร์เท่านั้น
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    สามารถรับยอดเสียของเพื่อนคืนได้เพียง 5% เท่านั้น
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียที่นำมาคำนวณ คือ ยอดเสียของเพื่อนที่ถูกเก็บไว้เป็นวันต่อวัน
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียที่นำมาคำนวณ คือ ยอดเสียของเพื่อนจากสัปดาห์ที่แล้ว
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ไม่สามารถรับยอดเสียของเพื่อนคืนย้อนหลังได้
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียของเพื่อนที่จะได้รับคืนจะถูกตัดทศนิยมออก
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box pb={1} px={2}>
                                <Divider sx={{ mt: -1 }} />
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#9c9c9c',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#696969',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => setIsOpenState(false)}>
                                    <Typography fontSize={16} fontWeight={500}>
                                        ปิดหน้าต่าง
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default AffiliateMain;