import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import CategoryClean from './../../assets/images/button_bg/clean.png';
import CategoryCleanHover from './../../assets/images/button_bg/cleanhover.png';
import DiceIcon from './../../assets/images/icons/dice.png';
import SlotIcon from './../../assets/images/icons/slot.png';
import ChipIcon from './../../assets/images/icons/chip.png';
import CardIcon from './../../assets/images/icons/cards_hires.png';
import LottoTab from "./../GameTab/LottoTab";
import GameTab from "./../GameTab/GameTab";

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const GameTabMember = () => {
    const gameList = ['Slot', 'Casino', 'Keno', 'Fish', 'Card', 'Trade', 'Poker', 'Esport', 'Cock', 'Sbo', 'Saba', 'Dbs', 'Fbs', 'Plb', 'Umb', 'Vsb', 'Afb', 'Lali', 'Favorites', 'Last_play', 'Lotto', 'Sport', 'Top'];

    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    let location = useLocation();
    const [gameTab, setGameTab] = useState<string>('slot');
    const { game_tab } = useParams();
    const [pathName, setPathName] = useState<string | null>(null);

    useEffect(() => {
        if(game_tab && (gameList.includes(capitalizeFirstLetter(game_tab.trim().toLowerCase()))))
        {
            setGameTab(game_tab);
        }
    }, [game_tab]);

    useEffect(() => {
        setPathName(location.pathname);
    }, [location]);

    return (
        <>
            <Box id="tab-game">
                <Grid container spacing={1} display={`flex`} justifyContent={`center`}>
                    <Grid item xs={3} md={3}>
                        <Button sx={{
                            background: `url("${CategoryClean}")`,
                            backgroundSize: 'cover',
                            color: 'black',
                            justifyContent: `center`,
                            '&:hover': {
                                background: `url("${CategoryCleanHover}")`,
                                backgroundSize: 'cover',
                                color: 'white'
                            },
                            height: '100%'
                        }} fullWidth onClick={() => setGameTab('slot')}>
                            <img src={SlotIcon} alt="Slot" width={`100%`} style={{ maxWidth: 20 }} />
                            <Typography>
                                สล็อต
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button sx={{
                            background: `url("${CategoryClean}")`,
                            backgroundSize: 'cover',
                            color: 'black',
                            justifyContent: `center`,
                            '&:hover': {
                                background: `url("${CategoryCleanHover}")`,
                                backgroundSize: 'cover',
                                color: 'white'
                            },
                            height: '100%'
                        }} fullWidth onClick={() => setGameTab('casino')}>
                            <img src={ChipIcon} alt="Chip" width={`100%`} style={{ maxWidth: 20 }} />
                            <Typography>
                                คาสิโน
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button sx={{
                            background: `url("${CategoryClean}")`,
                            backgroundSize: 'cover',
                            color: 'black',
                            justifyContent: `center`,
                            '&:hover': {
                                background: `url("${CategoryCleanHover}")`,
                                backgroundSize: 'cover',
                                color: 'white'
                            },
                            height: '100%'
                        }} fullWidth onClick={() => setGameTab('fish')}>
                            <img src={CardIcon} alt="Card" width={`100%`} style={{ maxWidth: 20 }} />
                            <Typography>
                                เกม
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button sx={{
                            background: `url("${CategoryClean}")`,
                            backgroundSize: 'cover',
                            color: 'black',
                            justifyContent: `center`,
                            '&:hover': {
                                background: `url("${CategoryCleanHover}")`,
                                backgroundSize: 'cover',
                                color: 'white'
                            },
                            height: '100%'
                        }} fullWidth onClick={() => setGameTab('lotto')}>
                            <img src={DiceIcon} alt="Dice" width={`100%`} style={{ maxWidth: 20 }} />
                            <Typography>
                                หวย
                            </Typography>
                        </Button>
                    </Grid>
                    {
                        cookies.get('access_token') ? <>
                            <Grid item xs={3} md={3}>
                                <Button sx={{
                                    background: `url("${CategoryClean}")`,
                                    backgroundSize: 'cover',
                                    color: 'black',
                                    justifyContent: `center`,
                                    '&:hover': {
                                        background: `url("${CategoryCleanHover}")`,
                                        backgroundSize: 'cover',
                                        color: 'white'
                                    },
                                    height: '100%'
                                }} fullWidth onClick={() => setGameTab('favorites')}>
                                    <img src={DiceIcon} alt="Dice" width={`100%`} style={{ maxWidth: 20 }} />
                                    <Typography>
                                        รายการโปรด
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Button sx={{
                                    background: `url("${CategoryClean}")`,
                                    backgroundSize: 'cover',
                                    color: 'black',
                                    justifyContent: `center`,
                                    '&:hover': {
                                        background: `url("${CategoryCleanHover}")`,
                                        backgroundSize: 'cover',
                                        color: 'white'
                                    },
                                    height: '100%'
                                }} fullWidth onClick={() => setGameTab('last_play')}>
                                    <img src={DiceIcon} alt="Dice" width={`100%`} style={{ maxWidth: 20 }} />
                                    <Typography>
                                        เล่นล่าสุด
                                    </Typography>
                                </Button>
                            </Grid>
                        </> : null
                    }
                </Grid>
            </Box>
            <Grid container spacing={2} mt={1} display={`flex`} justifyContent={`center`}>
                {
                    gameTab === 'lotto' ? <LottoTab /> : <GameTab game={gameTab} />
                }
            </Grid>
        </>
    )
}

export default GameTabMember;