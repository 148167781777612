import Cookies from "universal-cookie";
import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';

import { ApiService } from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { userDataType } from "../../types";
import DiceSpin from "../Loading/DiceSpin";
import { HiOutlineCash, HiOutlineRefresh } from "react-icons/hi";
import moment from "moment";
import { BiMoneyWithdraw } from "react-icons/bi";
import { GrList } from "react-icons/gr";
import { RiBillLine } from "react-icons/ri";
import { PiGameControllerFill } from "react-icons/pi";
import { PiPokerChipBold } from "react-icons/pi";
import { GiRayGun } from "react-icons/gi";
import ResultsPage from "../Results/ResultsPage";
import MemberDetailPopup from "./MemberDetailPopup";
import Banner from "../Header/Banner";
import { TiHeartFullOutline } from "react-icons/ti";
import { MdOutlineAirlineStops } from "react-icons/md";
import { MdFiberNew } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import GameTabMember from "./GameTabMember";

const MemberHome = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [userData, setUserData] = useState<userDataType | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentCredit, setCurrentCredit] = useState<number | null>(0);
    const [loadingCredit, setLoadingCredit] = useState<boolean>(false);
    const [dataDate, setDataDate] = useState<string>(`${moment().format("DD/MM/YYYY")}`);
    const [dataTime, setDataTime] = useState<string>(`${moment().format("H:m:s")}`);

    const [openPopupDetail, setOpenPopupDetail] = useState<boolean>(false);

    useEffect(() => {
        getMemberData();
    }, []);

    const getMemberData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getMemberDetail = await service.getMemberDetail(cookies.get('access_token'));

            if(getMemberDetail.data.status_code === 200)
            {
                setUserData(getMemberDetail.data.data);
                setDataDate(`${moment().format("DD/MM/YYYY")}`);
                setDataTime(`${moment().format("H:m:s")}`);
                getCredit();
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง (${getMemberDetail.data.message})`
                });
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง'
            });
        }
        setLoading(false);
    }

    const getCredit = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }
        else if(loadingCredit)
        {
            return;
        }

        setLoadingCredit(true);
        try {
            const service = new ApiService();
            const getCredit = await service.getCurrentCredit(cookies.get('access_token'));

            setCurrentCredit(getCredit.data.current_credit);
            if(getCredit.data.status_code === 200)
            {
                setDataDate(`${moment().format("DD/MM/YYYY")}`);
                setDataTime(`${moment().format("H:m:s")}`);
            }
        } catch (err) {
            console.log(err);
        }
        setLoadingCredit(false);
    }

    return (
        <Container sx={{ 
            px: 0
         }}>
            <Box mt={3}></Box>
            {
                (loading || !userData) ? <Box p={3} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                    <DiceSpin />
                </Box> : <Grid container spacing={2} display={`flex`} justifyContent={`center`}>
                    <MemberDetailPopup setIsOpenState={setOpenPopupDetail} isOpenState={openPopupDetail} />
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={3} color={'black'} sx={{
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)'
                        }}>
                            <Box p={2}>
                                <Box display={`flex`} justifyContent={`space-between`} alignContent={`center`} alignItems={`center`}>
                                    <Box display={`flex`} justifyContent={`center`}>
                                        <AccountCircleIcon sx={{
                                            fontSize: 32,
                                            color: '#000000'
                                        }} />
                                        &nbsp;
                                        <Typography align={'right'} fontSize={20}>
                                            {
                                                userData.phone
                                            }
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Button sx={{
                                            backgroundColor: 'white',
                                            border: '1px solid #bfbebe',
                                            borderRadius: '4px',
                                            transition: 'all .2s',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#ededed',
                                                fontWeight: 'bold',
                                            }
                                        }} onClick={() => {
                                            setOpenPopupDetail(!openPopupDetail);
                                        }}>
                                            <Typography color={'black'} display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                <InfoIcon sx={{ mr: 0.2 }} />
                                                ดูข้อมูล
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box width={`100%`}>
                                    <Typography fontSize={48} display={`flex`} alignContent={`center`} alignItems={`center`} fontWeight={`bold`}>
                                        ฿{parseFloat(String(currentCredit || "0")).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                        &nbsp;
                                        <HiOutlineRefresh className={`${loadingCredit ? `rotating` : ""}`} color="#000000" onClick={getCredit} style={{ cursor: 'pointer' }} />
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography display={`flex`} justifyContent={`flex-end`} fontSize={12}>
                                        ข้อมูล ณ วันที่ {
                                            dataDate
                                        } เวลา {
                                            dataTime
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={
                                    {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        cursor: 'pointer',
                                        transition: 'all .2s',
                                        '&:hover': {
                                            backgroundColor: '#ededed'
                                        }
                                    }
                                } onClick={() => navigate('/deposit')}>
                                    <Box p={2}>
                                        <Typography display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} fontSize={22}>
                                            <HiOutlineCash />&nbsp;ฝากเงิน
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={
                                    {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        cursor: 'pointer',
                                        transition: 'all .2s',
                                        '&:hover': {
                                            backgroundColor: '#ededed'
                                        }
                                    }
                                } onClick={() => navigate('/withdraw')}>
                                    <Box p={2}>
                                        <Typography display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} fontSize={22}>
                                            <BiMoneyWithdraw />&nbsp;ถอนเงิน
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={
                                    {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        cursor: 'pointer',
                                        transition: 'all .2s',
                                        '&:hover': {
                                            backgroundColor: '#ededed'
                                        }
                                    }
                                } onClick={() => navigate('/deposit-transactions')}>
                                    <Box p={2}>
                                        <Typography display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} fontSize={22}>
                                            <GrList />&nbsp;ประวัติฝากถอน
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={{
                            cursor: 'pointer',
                            transition: 'all .2s',
                            '&:hover': {
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)'
                            }
                        }} onClick={() => navigate('/game/slot')}>
                            <Box p={1} display={`flex`} flexDirection={`column`} justifyContent={`center`}>
                                <Box display={`flex`} justifyContent={`center`}>
                                    <PiGameControllerFill style={{
                                        fontSize: 32,
                                        color: 'white',
                                        padding: 5,
                                        backgroundColor: 'rgba(0, 0, 0, .8)',
                                        borderRadius: '999999px'
                                    }} />
                                </Box>
                                <Box>
                                    <Typography align="center" fontSize={24}>
                                        เกมส์ทั้งหมด
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={{
                            cursor: 'pointer',
                            transition: 'all .2s',
                            '&:hover': {
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)'
                            }
                        }} onClick={() => navigate('/game/favorites')}>
                            <Box p={1} display={`flex`} flexDirection={`column`} justifyContent={`center`}>
                                <Box display={`flex`} justifyContent={`center`}>
                                    <TiHeartFullOutline style={{
                                        fontSize: 32,
                                        color: 'white',
                                        padding: 5,
                                        backgroundColor: 'rgba(0, 0, 0, .8)',
                                        borderRadius: '999999px'
                                    }} />
                                </Box>
                                <Box>
                                    <Typography align="center" fontSize={24}>
                                        เกมส์โปรด
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={{
                            cursor: 'pointer',
                            transition: 'all .2s',
                            '&:hover': {
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)'
                            }
                        }} onClick={() => navigate('/game/top')}>
                            <Box p={1} display={`flex`} flexDirection={`column`} justifyContent={`center`}>
                                <Box display={`flex`} justifyContent={`center`}>
                                    <MdOutlineAirlineStops style={{
                                        fontSize: 32,
                                        color: 'white',
                                        padding: 5,
                                        backgroundColor: 'rgba(0, 0, 0, .8)',
                                        borderRadius: '999999px'
                                    }} />
                                </Box>
                                <Box>
                                    <Typography align="center" fontSize={24}>
                                        เกมส์ยอดนิยม
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={{
                            cursor: 'pointer',
                            transition: 'all .2s',
                            '&:hover': {
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)'
                            }
                        }} onClick={() => navigate('/game/new')}>
                            <Box p={1} display={`flex`} flexDirection={`column`} justifyContent={`center`}>
                                <Box display={`flex`} justifyContent={`center`}>
                                    <MdFiberNew style={{
                                        fontSize: 32,
                                        color: 'white',
                                        padding: 5,
                                        backgroundColor: 'rgba(0, 0, 0, .8)',
                                        borderRadius: '999999px'
                                    }} />
                                </Box>
                                <Box>
                                    <Typography align="center" fontSize={24}>
                                        เกมส์ใหม่ล่าสุด
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                        <Box width={`100%`} bgcolor={'white'} borderRadius={2} color={'black'} sx={{
                            cursor: 'pointer',
                            transition: 'all .2s',
                            '&:hover': {
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)'
                            }
                        }} onClick={() => navigate('/game/slot')}>
                            <Box p={1} display={`flex`} flexDirection={`column`} justifyContent={`center`}>
                                <Box display={`flex`} justifyContent={`center`}>
                                    <FaMoneyBillTrendUp style={{
                                        fontSize: 32,
                                        color: 'white',
                                        padding: 5,
                                        backgroundColor: 'rgba(0, 0, 0, .8)',
                                        borderRadius: '999999px'
                                    }} />
                                </Box>
                                <Box>
                                    <Typography align="center" fontSize={24}>
                                        เกมส์แตกเงินล้าน
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Banner disableBackground={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <GameTabMember />
                    </Grid>
                </Grid>
            }
        </Container>
    )
}

export default MemberHome;