import { Box, Grid, Typography } from "@mui/material";
import One from './../assets/images/about/index-lotto-features-1.webp';
import Two from './../assets/images/about/index-lotto-features-2.webp';
import Three from './../assets/images/about/index-lotto-features-3.webp';
import Four from './../assets/images/about/index-lotto-features-4.webp';

const About = () => {
    return (
        <Grid container spacing={0}>
            {/* <Grid item xs={12}>
                <hr/>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" color={"#ffffff"} fontSize={24} fontWeight={`bold`}>
                    เกี่ยวกับ GXY88
                </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display={`flex`} justifyContent={`center`}>
                            <img src={One} alt="one" style={{ width: '100%', maxWidth: '160px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={20} fontWeight={`bold`} color={'#ffffff'}>
                            ฝาก - ถอน ออโต้
                        </Typography>
                        <Box display={`flex`} justifyContent={`center`}>
                            <Typography align="center" color={`#faf7f7`} maxWidth={`230px`} sx={{ opacity: 0.85 }}>
                                ระบบฝากถอนอัตโนมัติ ตรวจสอบไวไม่เกิน 3นาที สามารถเดิมพันได้ทันที ไม่ต้องรอนาน
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display={`flex`} justifyContent={`center`}>
                            <img src={Two} alt="two" style={{ width: '100%', maxWidth: '160px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={20} fontWeight={`bold`} color={'#ffffff'}>
                            บริการลูกค้า 24 ชม.
                        </Typography>
                        <Box display={`flex`} justifyContent={`center`}>
                            <Typography align="center" color={`#faf7f7`} maxWidth={`230px`} sx={{ opacity: 0.85 }}>
                                มีทีมงานคอยให้คำปรึกษาตลอดการเดิมพัน สามารถช่วยตรวจสอบและแก้ไขปัญหาได้ตลอด 24ชม.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display={`flex`} justifyContent={`center`}>
                            <img src={Three} alt="three" style={{ width: '100%', maxWidth: '160px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={20} fontWeight={`bold`} color={'#ffffff'}>
                            ความมั่นคง
                        </Typography>
                        <Box display={`flex`} justifyContent={`center`}>
                            <Typography align="center" color={`#faf7f7`} maxWidth={`230px`} sx={{ opacity: 0.85 }}>
                                อัตราการจ่ายสูง และมีคนเข้าเล่นเดิมพันเยอะที่สุดในไทย หลักล้านก็ถอนได้ ไม่มีเงื่อนไข
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display={`flex`} justifyContent={`center`}>
                            <img src={Four} alt="four" style={{ width: '100%', maxWidth: '160px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={20} fontWeight={`bold`} color={'#ffffff'}>
                            ครบจบที่เดียว
                        </Typography>
                        <Box display={`flex`} justifyContent={`center`}>
                            <Typography align="center" color={`#faf7f7`} maxWidth={`230px`} sx={{ opacity: 0.85 }}>
                                สามารถเดิมพันได้ทุกรูปแบบไม่ว่าจะเป็น หวย สล็อต คาสิโน บอล เล่นได้ในเว็บเดียวไม่ต้องโยกเงิน
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default About;