import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, CircularProgress, FormControl, FormLabel, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    // {
    //     "bank_abbr": "TMW",
    //     "bank_code": "099",
    //     "bank_logo": "https://cdn.bullpay.me/images/logo/turemoney.png",
    //     "bank_name_en": "True Money Wallet",
    //     "bank_name_th": "ทรูมันนี่วอลเล็ท"
    // }
];

const isNumericString = (str: string) => {
    return /^\d+$/.test(str);
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const validate_password = (input: string) => {
    return input.includes(' ') ? false : true;
}

const steps = [
    'กรอกเบอร์โทรศัพท์',
    'กรอกข้อมูลบัญชีธนาคาร',
    'สำเร็จ',
];

const RegisterDesktop = () => {
    const query = useQuery();
    const cookies = new Cookies(null, { path: '/' });
    const by = query.get('by');
    const aff = query.get('aff');
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [regBy, setRegBy] = useState<string | null>(null);
    const [affBy, setAffBy] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [bankAccountNumberCorrect, setBankAccountNumberCorrect] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const lineRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLSelectElement>(null);
    const bankNumberRef = useRef<HTMLInputElement>(null);
    // const knowAtRef = useRef<HTMLSelectElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault();

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }
    }, []);

    useEffect(() => {
        if(by)
        {
            cookies.set('reg_by', by);
            setRegBy(by);

            addClick(by);
        }
        else
        {
            setRegBy(!cookies.get('reg_by') ? null : cookies.get('reg_by'));
            if(cookies.get('reg_by'))
            {
                addClick(cookies.get('reg_by'));
            }
        }
    }, [by]);

    useEffect(() => {
        if(aff)
        {
            cookies.set('aff', aff);
            setAffBy(aff);
        }
        else
        {
            setAffBy(!cookies.get('aff') ? null : cookies.get('aff'));
        }
    }, [aff]);

    const addClick = async (reg_by: string) => {
        try {
            const service = new ApiService();
            await service.addClickCount(reg_by);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    return cookies.remove('access_token');
                }
                else if(checkToken.status_code === 200)
                {
                    return navigate('/');
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const send_register = async () => {
        if(!usernameRef.current || !passwordRef.current || !firstnameRef.current || !lastnameRef.current || !lineRef.current || !bankRef.current || !bankNumberRef.current)
        {
            return;
        }

        const phone = usernameRef.current.value.trim();
        const password = passwordRef.current.value.trim();
        const first_name = firstnameRef.current.value.trim();
        const last_name = lastnameRef.current.value.trim();
        const line = lineRef.current.value.trim();
        const know_at = '';
        const bank = bankRef.current.value.trim();
        const bank_number = bankNumberRef.current.value.trim();

        if(phone === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เบอร์โทรศัพท์ไม่สามารถเว้นว่างได้'
            });
        }
        else if(password === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
            });
        }
        else if(first_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ชื่อจริงไม่สามารถเว้นว่างได้'
            });
        }
        else if(last_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'นามสกุลไม่สามารถเว้นว่างได้'
            });
        }
        else if(bank_number === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีธนาคารไม่สามารถเว้นว่างได้'
            });
        }
        else if(!validate_password(password))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'PIN ห้ามมีเว้นวรรค'
            });
        }
        else if(password.length < 6)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'PIN จำเป็นต้องมี 6 ตัว'
            });
        }
        // else if(!checkStringPassword(password))
        // {
        //     return Swal.fire({
        //         icon: 'error',
        //         title: 'แจ้งเตือน',
        //         text: 'รหัสผ่านจำเป็นต้องมีตัวอักษรตัวเล็ก ตัวใหญ่ และ ตัวเลข'
        //     });
        // }
        else if(!(isNumericString(bank_number)))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีจำเป็นต้องกรอกเฉพาะตัวเลขเท่านั้นค่ะ (ไม่ต้องมี - หรือ เว้นวรรค)'
            });
        }
        
        try {
            setLoading(true);
            const service = new ApiService();
            const register = await service.register({
                password: password,
                phone: phone,
                first_name: first_name,
                last_name: last_name,
                line: line,
                know_at: know_at,
                bank: bank,
                bank_number: bank_number,
                by: regBy,
                aff: affBy
            });

            setLoading(false);
            if(register.data.status_code === 200 && register.data.message === 'Success')
            {
                const expires = new Date();
                expires.setDate(expires.getDate() + 7);
                cookies.set('access_token', register.data.data.token, { expires });
                cookies.set('user', register.data.data.ufa_username, { expires });
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    // text: `Username: ${phone}<br>Password: ${password}`,
                    html: `สมัครสมาชิกเรียบร้อยแล้วค่ะ<br/>Username: <b>${phone}</b><br/>Password: <b>${password}</b>`
                }).then(() => {
                    window.location.reload();
                });
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: register.data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง"
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getBankAccInfo = async (e: ChangeEvent<HTMLInputElement>) => {
        if(!e.target || !bankRef.current || !bankNumberRef.current)
        {
            return;
        }

        try {
            const value = e.target.value;
            const element_fistname = document.getElementById("user_firstname") as HTMLInputElement;
            const element_lastname = document.getElementById("user_lastname") as HTMLInputElement;

            if(!element_fistname || !element_lastname)
            {
                return;
            }

            const currentLength = bankNumberRef.current.value.trim().length;
            if(value.length < 10)
            {
                element_fistname.value = "";
                element_lastname.value = "";
                setBankAccountNumberCorrect(0);
                return;
            }

            setBankAccountNumberCorrect(1);
            element_fistname.value = "กำลังโหลดข้อมูล...";
            element_lastname.value = "กำลังโหลดข้อมูล...";

            const service = new ApiService();
            const getBankInfo = await service.getBankAccInfo(bankRef.current.value.trim(), bankNumberRef.current.value.trim());

            if(currentLength !== bankNumberRef.current.value.trim().length)
            {
                return;
            }

            if(getBankInfo.data.status_code === 200)
            {
                let set_name = "";
                const split_fullname = getBankInfo.data.info.bank_acc_fullname.split(" ");

                set_name = split_fullname.length > 2 ? `${split_fullname[1]} ${split_fullname[2]}` : set_name = `${split_fullname[0]} ${split_fullname[1]}`
                element_fistname.value = set_name.split(" ")[0];
                element_lastname.value = set_name.split(" ")[1];
                setBankAccountNumberCorrect(2);
            }
            else
            {
                element_fistname.value = "";
                element_lastname.value = "";
                setBankAccountNumberCorrect(0);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const nextStep = () => {
        if(currentStep === 0)
        {
            if(!usernameRef.current || !passwordRef.current)
            {
                return;
            }
    
            const phone = usernameRef.current.value.trim();
            const password = passwordRef.current.value.trim();

            if(phone === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เบอร์โทรศัพท์ไม่สามารถเว้นว่างได้'
                });
            }
            else if(password === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
                });
            }
            else if(!validate_password(password))
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'PIN ห้ามมีเว้นวรรค'
                });
            }
            else if(password.length < 6)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'PIN จำเป็นต้องมี 6 ตัว'
                });
            }

            setCurrentStep(1);
        }
        else if(currentStep === 1)
        {
            const element_fistname = document.getElementById("user_firstname") as HTMLInputElement;
            const element_lastname = document.getElementById("user_lastname") as HTMLInputElement;

            if(!element_fistname || !element_lastname)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณากรอกหมายเลขบัญชีให้ถูกต้อง'
                });
            }

            send_register();
        }
    }

    return (
        <form name="user_register" id="user_register" onSubmit={send_register}>
            <Box p={2}>
                <Typography align="center" fontSize={20} color={'#313131'}>
                    กรอกข้อมูลเพื่อสมัครสมาชิก
                </Typography>
                <Typography align="center" color={'#9c9c9c'}>
                    กรุณาใช้ข้อมูลที่ถูกต้องและเป็นจริงในการสมัครเท่านั้น
                </Typography>
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {
                            steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Box>
                <Box id="step_phone" sx={{ display: currentStep === 0 ? 'block' : 'none' }}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_phone'
                            type={`tel`}
                            fullWidth
                            autoComplete="user_phone"
                            placeholder="หมายเลขเบอร์โทรศัพท์ 10 หลักสำหรับเข้าสู่ระบบ"
                            inputRef={usernameRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_password'
                            type={showPassword ? 'tel' : 'tel'}
                            fullWidth
                            autoComplete="user_password"
                            placeholder="PIN 6 หลัก"
                            inputRef={passwordRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box id="step_bank_acc" sx={{ display: currentStep === 1 ? 'block' : 'none' }}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <Select
                            fullWidth
                            inputRef={bankRef}
                            sx={
                                {
                                    backgroundColor: 'white',
                                }
                            }
                            defaultValue={`SCB`}
                        >
                            {
                                bank_list.map((element, index: number) => {
                                    return (
                                        <MenuItem value={element.bank_abbr} key={`register-bank-${index}`}>
                                            <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                                                <img src={element.bank_logo} alt={`bank_logo_${element.bank_abbr}`} style={{ maxWidth: 20 }} />
                                                &nbsp;
                                                {element.bank_name_th}
                                            </Box>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <Typography color={`#9c9c9c`} fontSize={13} mb={1}>
                        ธนาคารที่ง่ายต่อการตรวจสอบ: ไทยพาณิชย์ (SCB)
                    </Typography>
                    <FormControl fullWidth>
                        <OutlinedInput
                            name='user_bank_account'
                            type={`tel`}
                            fullWidth
                            autoComplete="user_bank_account"
                            placeholder="เลขที่บัญชี"
                            inputRef={bankNumberRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                            onChange={getBankAccInfo}
                            endAdornment={
                                <InputAdornment position="end">
                                    {
                                        bankAccountNumberCorrect === 1 ? <CircularProgress size={"small"} sx={{ color: 'black' }} /> : (
                                            bankAccountNumberCorrect === 0 ? <ClearIcon fontSize='small' sx={{ color: 'red' }} /> : <CheckIcon fontSize='small' sx={{ color: 'green' }} />
                                        )
                                    }
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_firstname'
                            id="user_firstname"
                            type={`text`}
                            fullWidth
                            autoComplete="user_firstname"
                            placeholder="ชื่อจริง"
                            inputRef={firstnameRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                            readOnly
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <OutlinedInput
                            name='user_lastname'
                            id="user_lastname"
                            type={`text`}
                            fullWidth
                            autoComplete="user_lastname"
                            placeholder="นามสกุล"
                            inputRef={lastnameRef}
                            sx={
                                {
                                    backgroundColor: 'white'
                                }
                            }
                            readOnly
                        />
                    </FormControl>
                </Box>
                <FormControl fullWidth sx={{ mt: 1, display: 'none' }}>
                    <OutlinedInput
                        name='user_line'
                        type={`text`}
                        // size='small'
                        fullWidth
                        autoComplete="user_line"
                        placeholder="ไลน์ไอดี"
                        inputRef={lineRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <Button fullWidth sx={
                    {
                        mt: 1,
                        py: 1,
                        backgroundColor: '#000000',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#3d3d3d',
                            color: 'white',
                        }
                    }
                } onClick={nextStep} disabled={loading}>
                    <Typography fontSize={16} fontWeight={500}>
                        {
                            loading ? <CircularProgress size={20} sx={
                                {
                                    color: 'white'
                                }
                            } /> : (
                                currentStep === 0 ? "ต่อไป" : "สมัครสมาชิก"
                            )
                        }
                    </Typography>
                </Button>
            </Box>
        </form>
    )
}

export default RegisterDesktop;