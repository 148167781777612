import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { listPromotionsType } from "../../types";
import { Box, Grid, Typography, CircularProgress, Button, Container } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import PromotionPopup from "./PromotionPopup";

const PromotionMain = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [promotionData, setPromotionData] = useState<listPromotionsType[] | null>(null);
    const [showPromotion, setShowPromotion] = useState<listPromotionsType | null>(null);

    useEffect(() => {
        getPromotionData();
    }, []);

    const getPromotionData = async () => {
        setPromotionData([]);
        setLoaded(true);
        
        try {
            const service = new ApiService();
            const getPromotionData = await service.getListPromotion();

            if(getPromotionData.data.status_code === 200)
            {
                setPromotionData(getPromotionData.data.promotions);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleShowPromotion = (promotion_id: number) => {
        const findIndex = promotionData?.findIndex((element: listPromotionsType) => element.id === promotion_id);
        if(findIndex === undefined || findIndex === -1 || promotionData === null)
        {
            return;
        }

        setShowPromotion(promotionData[findIndex]);
    }

    return (
        loaded && promotionData ? <Box p={2} sx={{ color: 'black' }}>
            <PromotionPopup promotion_detail={showPromotion} setShowPromotion={setShowPromotion} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography fontSize={20} color={'black'}>
                        โปรโมชั่นทั้งหมด { promotionData.length } รายการ
                    </Typography>
                </Grid>
                {
                    promotionData?.sort((a: listPromotionsType, b: listPromotionsType) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).map((element: listPromotionsType, index: number) => {
                        return (
                            <Grid item xs={12} md={4} key={`promotion-${index}`}>
                                <Box sx={
                                    {
                                        boxShadow: "10px 2px 6px 2px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: 'rgba(255, 255, 255, .5)',
                                        transition: 'all .2s',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            scale: '1.03'
                                        }
                                    }
                                } onClick={() => handleShowPromotion(element.id)}>
                                    <Box>
                                        <img src={`${process.env.REACT_APP_API_URL}/image/${element.poster_image}`} alt={`promotion_${element.name}`} style={{ maxWidth: '100%' }} />
                                    </Box>
                                    <Box pb={1}>
                                        <Typography fontSize={20} align="center">
                                            {element.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default PromotionMain;