import { Box, Button, Grid, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ThaiLottoBall from "../../assets/images/ball/thai_lotto_ball.png";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InputNumber from "../InputNumber/InputNumber";
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from "react";
import { checkedEnableNumberType, inputNumberListType, listLimitType, lottoDetailPlayPageType, periodDetailPlayPageType } from "../../types";
import DiceSpin from "../Loading/DiceSpin";
import DeleteIcon from '@mui/icons-material/Delete';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import Swal from "sweetalert2";
import TabInputPriceLotto from "./TabInputPriceLotto";
import { ApiService } from "../../services/api.service";
import moment from "moment";
import Cookies from "universal-cookie";
import { io } from 'socket.io-client';

const socket = io('https://api.gxy88.com');
const PlayLotto = () => {
    const cookies = new Cookies(null, { path: '/' });
    const { lotto_id } = useParams();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({
        query: '(min-width: 900px)'
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [lottoDetail, setLottoDetail] = useState<lottoDetailPlayPageType | null>(null);
    const [periodDetail, setPeriodDetail] = useState<periodDetailPlayPageType | null>(null);
    const [limitList, setLimitList] = useState<listLimitType[] | null>(null);
    const [inputNumber, setInputNumber] = useState<inputNumberListType[]>([]);
    const [logsSeed, setLogsSeed] = useState<string[]>([]);
    const [groupedData, setGroupedData] = useState<{ [key: string]: inputNumberListType[] }>({});
    const [openTabInputPrice, setOpenTabInputPrice] = useState<boolean>(false);
    const [currentRemainTime, setCurrentRemainTime] = useState<string>('');

    const [checkedEnableNumber, setCheckedEnableNumber] = useState<checkedEnableNumberType>({
        six_number: false,
        first_three_number: false,
        last_three_number: false,
        last_two_number: false
    });

    useEffect(() => {
        socket.on('message', (msg) => {
            console.log(msg);
            if(msg.type == 'update_limit')
            {
                if(parseInt(`${lotto_id}`) === msg.lotto_id)
                {
                    setLimitList(msg.limit_list);
                }
            }
        });

        return () => {
            socket.off('message');
        };
    }, []);

    useEffect(() => {
        getLottoDetail();
    }, []);

    const timeIntervelDiffTime = (targetDateInput: Date) => {
        const diffTimeResult = diffTime(targetDateInput);
        if(!diffTimeResult)
        {
            return;
        }
        setCurrentRemainTime(diffTimeResult);

        setTimeout(() => {
            timeIntervelDiffTime(targetDateInput);
        }, 1000);
    }

    const diffTime = (targetDateInput: Date) => {
        const targetDate = moment(targetDateInput);
        const today = moment();
        let diff = targetDate.diff(today, 'days');
        let result;

        if (diff >= 1) {
            result = `${diff} วัน`;
        }
        else
        {
            // คำนวณจำนวนชั่วโมงที่เหลือ
            diff = targetDate.diff(today, 'hours');
            if (diff >= 1)
            {
                result = `${diff} ชั่วโมง`;
            }
            else
            {
                diff = targetDate.diff(today, 'minutes');
                if(diff >= 1)
                {
                    result = `${diff} นาที`;
                }
                else
                {
                    diff = targetDate.diff(today, 'seconds');
                    if(diff < 0)
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: `หมดเวลาแทงแล้วค่ะ`
                        }).then(() => {
                            navigate('/');
                        });

                        return false;
                    }
                    result = `${diff} วินาที`;
                }
            }
        }

        return result;
    }

    const getLottoDetail = async () => {
        if(!cookies.get('access_token'))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณาเข้าสู่ระบบก่อนเล่นค่ะ'
            }).then(() => {
                navigate('/');
            });
        }

        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getDetail = await service.getLottoDetail(parseInt(`${lotto_id}`));

            if(getDetail.data.status_code === 200)
            {
                setLottoDetail(getDetail.data.data.detail);
                setPeriodDetail(getDetail.data.data.period);
                setLimitList(getDetail.data.data.limit_list);
                setCheckedEnableNumber(getDetail.data.data.enable_number);
                setLoading(false);
                timeIntervelDiffTime(getDetail.data.data.period.end_dt);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง'
                }).then(() => {
                    navigate('/');
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        const groupData = (data: inputNumberListType[]) => {
            let grouped: { [key: string]: inputNumberListType[] } = {};
            data.forEach((item) => {
                const groupKey = `${item.amountNumberState}-${item.subtype}`;
                // let duplicate_number = -1;

                if (!grouped[groupKey])
                {
                    grouped[groupKey] = [];
                }
                // else
                // {
                //     const duplicate_number = grouped[groupKey].findIndex((element: inputNumberListType) => element.number === item.number);
                //     if(duplicate_number !== -1)
                //     {
                //         Swal.fire({
                //             icon: 'warning',
                //             title: 'แจ้งเตือน',
                //             text: `เลข ${item.number} มีในระบบแล้ว ไม่สามารถเพิ่มซ้ำได้ค่ะ`
                //         });
                //     }
                // }

                grouped[groupKey].push(item);
            });
            return grouped;
        };

        setGroupedData(groupData(inputNumber));
    }, [inputNumber]);

    const deleteAll = () => {
        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: `คุณแน่ใจหรือไม่ ที่จะลบเลขที่คุณกรอกไว้ทั้งหมดจำนวน ${inputNumber.length} ตัวเลข`,
            confirmButtonText: 'ตกลง',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก'
        }).then((res) => {
            if(res.isConfirmed)
            {
                setInputNumber([]);
                setLogsSeed([]);
            }
        })
    }

    const deleteLastAdded = () => {
        if(logsSeed.length <= 0)
        {
            return;
        }

        const tmp_inputNumber = [...inputNumber.filter((element: inputNumberListType) => element.seed !== logsSeed[logsSeed.length - 1])];
        const tmp_logsSeed = [...logsSeed.filter((element: string) => element !== logsSeed[logsSeed.length - 1])];
        setInputNumber(tmp_inputNumber);
        setLogsSeed(tmp_logsSeed);
    }

    const deleteNumber = (deleteNumber: string, deleteSubType: number, deleteAmountNumberState: number) => {
        const findIndex = inputNumber.findIndex((element: inputNumberListType) => element.number === deleteNumber && element.subtype === deleteSubType && element.amountNumberState === deleteAmountNumberState)
        
        const tmp_inputNumber: inputNumberListType[] = [];
        inputNumber.forEach((element: inputNumberListType, index: number) => {
            if(index !== findIndex)
            {
                tmp_inputNumber.push(element);
            }
        });
        setInputNumber(tmp_inputNumber);
    }

    return (
        !lottoDetail ? <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} height={`100dvh`} width={`100%`}>
            <DiceSpin />
        </Box> : <>
            <TabInputPriceLotto lotto_id={!lotto_id ? 0 : parseInt(`${lotto_id}`)} list={inputNumber} openTabInputPrice={openTabInputPrice} setOpenTabInputPrice={setOpenTabInputPrice} limitList={limitList} />
            <Grid container spacing={isDesktop ? 2 : .5} p={isDesktop ? 2 : 0} sx={{ transition: 'all .5s' }}>
                {
                    !isDesktop && <Grid item xs={12}>
                        <Box sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: '100%',
                            maxHeight: '100%',
                        }}>
                            <Grid container>
                                <Grid item xs={12} py={1} px={2} color={`white`} sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }} className="bg-class">
                                    <Box display={`flex`}>
                                        <img src={ThaiLottoBall} alt="บอล-หวยไทย" width={25} height={25} />
                                        &nbsp;
                                        <Typography>
                                            {lottoDetail.name}
                                        </Typography>
                                    </Box>
                                    <Box display={`flex`}>
                                        <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                            <AccessTimeIcon sx={{ fontSize: 18 }} />
                                            &nbsp;
                                            {
                                                currentRemainTime
                                            }
                                            {/* 6 วัน */}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                }
                <Grid item xs={5} sm={5} md={8} lg={8}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: '83dvh',
                        maxHeight: '83dvh',
                        borderRadius: isDesktop ? 3 : 0,
                        overflow: 'scroll'
                    }}>
                        <Grid container>
                            <Grid item xs={12} borderRadius={`10px 10px 0px 0px`} py={1} px={2} color={`white`} sx={{
                                display: isDesktop ? 'flex' : 'none',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                alignItems: 'center'
                            }} className="bg-class">
                                <Box display={`flex`}>
                                    <img src={ThaiLottoBall} alt="บอล-หวยไทย" width={25} height={25} />
                                    &nbsp;
                                    <Typography>
                                        {lottoDetail.name}
                                    </Typography>
                                </Box>
                                <Box display={`flex`}>
                                    <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                        <AccessTimeIcon sx={{ fontSize: 18 }} />
                                        &nbsp;
                                        {
                                            currentRemainTime
                                        }
                                        {/* 6 วัน */}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={isDesktop ? 2 : 0}>
                                {
                                    inputNumber.length > 0 ? <>
                                        
                                        {
                                            isDesktop ? <>
                                                <Box mb={1} display={`flex`} justifyContent={`space-between`}>
                                                    <Typography fontSize={18}>
                                                        {
                                                            inputNumber.length
                                                        } รายการ
                                                    </Typography>
                                                    <Grid display={`flex`} minWidth={50} width={250}>
                                                        <Button fullWidth
                                                            sx={{
                                                                display: 'flex',
                                                                backgroundColor: 'rgba(255, 0, 0, .05)',
                                                                border: '1px solid red',
                                                                borderRadius: '4px',
                                                                transition: 'all .2s',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgba(255, 0, 0, .1)',
                                                                    fontWeight: 'bold'
                                                                },
                                                                mx: .5
                                                            }}
                                                            disableRipple
                                                            disableFocusRipple
                                                            onClick={deleteAll}
                                                        >
                                                            <Typography color={'black'} fontSize={14} align="center">
                                                                ลบทั้งหมด
                                                            </Typography>
                                                            <Typography color={'red'} fontSize={14} align="center">
                                                                &nbsp;({
                                                                    inputNumber.length
                                                                })
                                                            </Typography>
                                                        </Button>
                                                        <Button fullWidth
                                                            sx={{
                                                                display: 'flex',
                                                                backgroundColor: 'rgba(255, 0, 0, .05)',
                                                                border: '1px solid red',
                                                                borderRadius: '4px',
                                                                transition: 'all .2s',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgba(255, 0, 0, .1)',
                                                                    fontWeight: 'bold'
                                                                },
                                                                mx: .5
                                                            }}
                                                            disableRipple
                                                            disableFocusRipple
                                                            onClick={deleteLastAdded}
                                                        >
                                                            <Typography color={'black'} fontSize={14} align="center">
                                                                ลบล่าสุด
                                                            </Typography>
                                                            <Typography color={'red'} fontSize={14} align="center">
                                                                &nbsp;({
                                                                    inputNumber.filter((element: inputNumberListType) => element.seed === logsSeed[logsSeed.length - 1]).length
                                                                })
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Box>
                                                <Swiper slidesPerView={3.5} spaceBetween={10}>
                                                    {
                                                        Object.entries(groupedData).map(([group, items]) => (
                                                            <SwiperSlide key={group}>
                                                                <Box sx={{
                                                                    border: 'solid 1px #e8e8e8',
                                                                    maxWidth: 300
                                                                }}>
                                                                    <Box bgcolor={'#f8f8f8'} px={2} py={.5}>
                                                                        <Typography fontSize={18}>
                                                                            {
                                                                                group.split("-")[0] === "3" && group.split("-")[1] === "1" ? "3 ตัวบน" :
                                                                                group.split("-")[0] === "3" && group.split("-")[1] === "3" ? "3 ตัวบนโต๊ด" :
                                                                                group.split("-")[0] === "2" && group.split("-")[1] === "1" ? "2 ตัวบน" :
                                                                                group.split("-")[0] === "2" && group.split("-")[1] === "1" ? "2 ตัวบน" :
                                                                                group.split("-")[0] === "2" && group.split("-")[1] === "3" ? "2 ตัวล่าง" :
                                                                                group.split("-")[0] === "4" && group.split("-")[1] === "1" ? "วิ่งบน" :
                                                                                group.split("-")[0] === "4" && group.split("-")[1] === "2" ? "วิ่งล่าง" :
                                                                                group
                                                                            }
                                                                            &nbsp;
                                                                            ({
                                                                                items.length
                                                                            } รายการ)
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box px={2}>
                                                                        {
                                                                            items.map((item, index) => (
                                                                                <Box display={`flex`} justifyContent={`space-between`} key={index} py={.3}>
                                                                                    <Typography display={`flex`} justifyContent={`center`}>
                                                                                        {index + 1}.&nbsp;{item.number}
                                                                                    </Typography>
                                                                                    <Typography display={`flex`} justifyContent={`center`}>
                                                                                        <DeleteIcon fontSize="small" sx={{
                                                                                            color: '#c4c4c4',
                                                                                            cursor: 'pointer',
                                                                                            transition: 'all .2s',
                                                                                            '&:hover': {
                                                                                                color: '#cf1f2d'
                                                                                            }
                                                                                        }} onClick={() => {
                                                                                            deleteNumber(item.number, item.subtype, item.amountNumberState)
                                                                                        }} />
                                                                                    </Typography>
                                                                                </Box>
                                                                            ))
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            </SwiperSlide>
                                                        ))
                                                    }
                                                </Swiper>
                                            </> : <Box display={`flex`} justifyContent={`space-between`} flexDirection={`column`} height={"80dvh"}>
                                                <Box>
                                                    <Typography align="center" fontSize={18}>
                                                        {inputNumber.length + 1} รายการ
                                                    </Typography>
                                                    {
                                                        Object.entries(groupedData).map(([group, items]) => (
                                                            <Box>
                                                                <Box bgcolor={'#f8f8f8'} px={2} py={.5} display={`flex`} justifyContent={`space-between`}>
                                                                    <Typography fontSize={18}>
                                                                        {
                                                                            group.split("-")[0] === "3" && group.split("-")[1] === "1" ? "3 ตัวบน" :
                                                                            group.split("-")[0] === "3" && group.split("-")[1] === "3" ? "3 ตัวบนโต๊ด" :
                                                                            group.split("-")[0] === "2" && group.split("-")[1] === "1" ? "2 ตัวบน" :
                                                                            group.split("-")[0] === "2" && group.split("-")[1] === "1" ? "2 ตัวบน" :
                                                                            group.split("-")[0] === "2" && group.split("-")[1] === "3" ? "2 ตัวล่าง" :
                                                                            group.split("-")[0] === "4" && group.split("-")[1] === "1" ? "วิ่งบน" :
                                                                            group.split("-")[0] === "4" && group.split("-")[1] === "2" ? "วิ่งล่าง" :
                                                                            group
                                                                        }
                                                                    </Typography>
                                                                    <Typography>
                                                                        ({
                                                                            items.length
                                                                        })
                                                                    </Typography>
                                                                </Box>
                                                                <Box px={2}>
                                                                    {
                                                                        items.map((item, index) => (
                                                                            <Box display={`flex`} justifyContent={`space-between`} key={index} py={.3}>
                                                                                <Typography display={`flex`} justifyContent={`center`}>
                                                                                    {index + 1}.&nbsp;{item.number}
                                                                                </Typography>
                                                                                <Typography display={`flex`} justifyContent={`center`}>
                                                                                    <DeleteIcon fontSize="small" sx={{
                                                                                        color: '#c4c4c4',
                                                                                        cursor: 'pointer',
                                                                                        transition: 'all .2s',
                                                                                        '&:hover': {
                                                                                            color: '#cf1f2d'
                                                                                        }
                                                                                    }} onClick={() => {
                                                                                        deleteNumber(item.number, item.subtype, item.amountNumberState)
                                                                                    }} />
                                                                                </Typography>
                                                                            </Box>
                                                                        ))
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                                <Box px={1}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Button fullWidth
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    backgroundColor: 'rgba(255, 0, 0, .05)',
                                                                    border: '1px solid red',
                                                                    borderRadius: '4px',
                                                                    transition: 'all .2s',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(255, 0, 0, .1)',
                                                                        fontWeight: 'bold'
                                                                    },
                                                                    height: '100%'
                                                                }}
                                                                disableRipple
                                                                disableFocusRipple
                                                                onClick={deleteAll}
                                                            >
                                                                <Typography color={'black'} fontSize={12} align="center">
                                                                    ลบทั้งหมด
                                                                </Typography>
                                                                <Typography color={'red'} fontSize={12} align="center">
                                                                    ({
                                                                        inputNumber.length
                                                                    })
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button fullWidth
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    backgroundColor: 'rgba(255, 0, 0, .05)',
                                                                    border: '1px solid red',
                                                                    borderRadius: '4px',
                                                                    transition: 'all .2s',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(255, 0, 0, .1)',
                                                                        fontWeight: 'bold'
                                                                    },
                                                                    height: '100%'
                                                                }}
                                                                disableRipple
                                                                disableFocusRipple
                                                                onClick={deleteLastAdded}
                                                            >
                                                                <Typography color={'black'} fontSize={12} align="center">
                                                                    ลบล่าสุด
                                                                </Typography>
                                                                <Typography color={'red'} fontSize={12} align="center">
                                                                    ({
                                                                        inputNumber.filter((element: inputNumberListType) => element.seed === logsSeed[logsSeed.length - 1]).length
                                                                    })
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        }
                                    </> : <Typography fontSize={14} align="center" color={`#8c8b8b`} mt={2}>
                                        ยังไม่มีข้อมูล กรุณาใส่เลข ที่ต้องการแทง
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4}>
                    <Box sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: '83dvh',
                        maxHeight: '83dvh',
                        borderRadius: isDesktop ? 3 : 0
                    }}>
                        <Box p={2}>
                            <InputNumber
                                setInputNumber={setInputNumber}
                                inputNumber={inputNumber}
                                logsSeed={logsSeed}
                                setLogsSeed={setLogsSeed}
                                deleteLastAdded={deleteLastAdded}
                                openTabInputPrice={openTabInputPrice}
                                setOpenTabInputPrice={setOpenTabInputPrice}
                                checkedEnableNumber={checkedEnableNumber}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default PlayLotto;