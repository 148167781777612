import { Box, Container, Grid, Typography } from "@mui/material";
import Payment from './../../assets/images/icon-payment.png';
import { useEffect, useState } from "react";
import { ApiService } from "../../services/api.service";

const Footer = () => {
    const [listProvidersLogo, setListProvidersLogo] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const service = new ApiService();
                const getList = await service.getLogoListProviders();

                if(getList.data.status_code === 200)
                {
                    setListProvidersLogo(getList.data.images);
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        <Box mt={5}>
            <Box width={`100%`} sx={{
                // backgroundColor: '#696969',
                color: 'black'
            }}>
                <hr />
                {
                    listProvidersLogo.length > 0 ? <Container sx={{ mb: 3 }}>
                        <Grid container spacing={1} display={`flex`} justifyContent={`center`}>
                            {
                                listProvidersLogo.map((element: string, index: number) => {
                                    return (
                                        <Grid item xs={1.3} md={.8} lg={.5} key={`${element.split(".")[0]}-${index}`} display={`flex`} justifyContent={`center`}>
                                            <img src={`${process.env.REACT_APP_API_URL}/image/providers/${element}`} alt={element.split(".")[0]} style={{
                                                width: '100%',
                                                maxWidth: '60px'
                                            }} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container> : null
                }
                <Container>
                    <Typography fontSize={22} fontWeight={`bold`} align="center">
                        เว็บแทงหวย GXY88 บริการหวยครบวงจร แทงง่ายเล่นได้ทั้งวัน
                    </Typography>
                    <Typography align="center" my={1}>
                        เว็บแทงหวยออนไลน์ GXY88 ที่มาพร้อมกับบริการหวยครบวงจร ให้คุณได้สัมผัสประสบการณ์แทงหวยที่ง่ายดายและสะดวกสบาย ลุ้นรางวัลใหญ่ได้ตลอด 24 ชั่วโมง พร้อมสร้างรายได้แบบไม่มีขีดจำกัด GXY88 การันตีความปลอดภัยและความน่าเชื่อถือ พร้อมเปิดให้แทงหวยได้ทุกประเภทไม่ว่าจะเป็น หวยลาว หวยใต้ดิน หวยรัฐบาล หวยยี่กี หวยฮานอย หรือหวยจากต่างประเทศ ก็มีให้เลือกอย่างครบครัน
                    </Typography>
                    <Typography fontSize={22} fontWeight={`bold`} align="center">
                        PAYMENTS ACCEPTED
                    </Typography>
                    <Box width={`100%`} display={`flex`} justifyContent={`center`} mt={1}>
                        <img src={Payment} alt={`payment`} width={`100%`} style={{ maxWidth: 500 }} />
                    </Box>
                </Container>
            </Box>
            <Box width={`100%`} sx={{
                mt: 1,
                backgroundColor: '#000000',
                py: 1
            }}>
                <Container>
                    <Typography align="center" color={`rgba(255, 255, 255, .8)`} fontWeight={`bold`}>
                        Copyright &copy; 2024 GXY88. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    )
}

export default Footer;