import Cookies from "universal-cookie";
import { ApiService } from "../../../services/api.service";
import { useState, useEffect } from "react";
import { Avatar, Box, Button, ButtonGroup, CircularProgress, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { HiOutlineRefresh } from "react-icons/hi";
import Swal from "sweetalert2";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMediaQuery } from "react-responsive";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from "react-router-dom";
import { PiGameControllerFill, PiPokerChipBold } from "react-icons/pi";
import { RiBillLine } from "react-icons/ri";
import { GiRayGun } from "react-icons/gi";
import { userDataType } from "../../../types";

interface PropsType {
    openDepositWithdrawMenu: (depositorwithdraw: number) => void;
}

const SubDetail = ({ openDepositWithdrawMenu }: PropsType) => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 890px)'
    });

    const cookies = new Cookies(null, { path: '/' });
    const [currentCredit, setCurrentCredit] = useState<number | null>(0);
    const [loadingCredit, setLoadingCredit] = useState<boolean>(false);

    const [userData, setUserData] = useState<userDataType | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if(cookies.get('access_token'))
        {
            getMemberData();
            getCredit();
        }
    }, []);

    const getCredit = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }
        else if(loadingCredit)
        {
            return;
        }

        setLoadingCredit(true);
        try {
            const service = new ApiService();
            const getCredit = await service.getCurrentCredit(cookies.get('access_token'));

            setCurrentCredit(getCredit.data.current_credit)
        } catch (err) {
            console.log(err);
        }
        setLoadingCredit(false);
    }

    const logout = () => {
        cookies.remove('access_token');
        Swal.fire({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'ออกจากระบบเรียบร้อยแล้วค่ะ'
        }).then((res) => {
            if(res)
            {
                window.location.reload();
            }
        });
    }

    const getMemberData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getMemberDetail = await service.getMemberDetail(cookies.get('access_token'));

            if(getMemberDetail.data.status_code === 200)
            {
                setUserData(getMemberDetail.data.data);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง (${getMemberDetail.data.message})`
                });
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง'
            });
        }
        setLoading(false);
    }

    return ( 
        <>
            {/* <Button sx={
                {
                    mx: .5,
                    borderRadius: 2,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 3,
                        lg: 3
                    },
                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                    '&:hover': {
                        scale: '105%'
                    },
                    color: 'white',
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38,
                    transition: 'all .2s'
                }
            } disableFocusRipple disableRipple onClick={() => openDepositWithdrawMenu(1)}>
                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} sx={{
                    fontSize: {
                        xs: 10,
                        sm: 10,
                        md: 14,
                        lg: 14,
                    }
                }}>
                    ฝากเงิน
                </Typography>
            </Button> */}
            {/* <Button sx={
                {
                    cursor: 'default',
                    mx: .5,
                    borderRadius: 4,
                    px: {
                        xs: .5,
                        sm: .5,
                        md: 3,
                        lg: 3
                    },
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, .5)'
                    },
                    color: 'white',
                    textTransform: 'none',
                    height: '100%',
                    maxHeight: 38
                }
            } disableFocusRipple disableRipple>
                <Typography fontSize={14} display={`flex`} alignContent={`center`} alignItems={`center`}>
                    {parseFloat(String(currentCredit)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    &nbsp;
                    <HiOutlineRefresh className={`${loadingCredit ? `rotating` : ""}`} color="#f4c53e" onClick={getCredit} style={{ cursor: 'pointer' }} />
                </Typography>
            </Button> */}
            <ButtonGroup variant="outlined" sx={{ 
                '& .MuiButtonGroup-grouped': { borderColor: 'white', '&:hover': {
                    borderColor: 'white'
                } }  // เปลี่ยนสีกรอบของปุ่ม
            }}>
                <Button sx={{
                    backgroundColor: '#000000',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#242323'
                    }
                }}>
                    <AccountCircleIcon sx={{
                        fontSize: 22,
                        color: 'white'
                    }} />&nbsp;{
                        (loading || !userData) ? <CircularProgress sx={{ fontSize: 12 }} /> : userData.phone
                    }
                </Button>
                <Button sx={{
                    backgroundColor: '#000000',
                    color: 'white',
                    p: 0,
                    '&:hover': {
                        backgroundColor: '#242323'
                    }
                }} onClick={handleClick}>
                    {
                        open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                </Button>
            </ButtonGroup>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    navigate('/game/slot');
                    handleClose();
                }}>
                    <Box display={`flex`} justifyContent={`center`}>
                        <PiGameControllerFill style={{
                            fontSize: 14,
                            color: 'white',
                            padding: 5,
                            backgroundColor: 'rgba(0, 0, 0, .8)',
                            borderRadius: '999999px'
                        }} />
                    </Box>&nbsp;สล็อต
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate('/game/casino');
                    handleClose();
                }}>
                    <Box display={`flex`} justifyContent={`center`}>
                        <PiPokerChipBold style={{
                            fontSize: 14,
                            color: 'white',
                            padding: 5,
                            backgroundColor: 'rgba(0, 0, 0, .8)',
                            borderRadius: '999999px'
                        }} />
                    </Box>&nbsp;คาสิโน
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate('/game/fish');
                    handleClose();
                }}>
                    <Box display={`flex`} justifyContent={`center`}>
                        <GiRayGun style={{
                            fontSize: 14,
                            color: 'white',
                            padding: 5,
                            backgroundColor: 'rgba(0, 0, 0, .8)',
                            borderRadius: '999999px'
                        }} />
                    </Box>&nbsp;ยิงปลา
                </MenuItem>
                <Divider sx={{ my: '0px !important', py: '0px !important', }} />
                <MenuItem onClick={() => {
                    logout();
                    handleClose();
                }} sx={{ color: '#e62727', py: .5 }}>
                    <ExitToAppIcon />&nbsp;ออกจากระบบ
                </MenuItem>
            </Menu>
        </>
    )
}

export default SubDetail;